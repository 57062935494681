import React, { useState } from "react"
import CountUp from "react-countup"
import { Helmet } from "react-helmet"
import ModalVideo from "react-modal-video"
import VisibilitySensor from "react-visibility-sensor"
import Layout from "../components/layout"
import Macroair from "../images/Macroair-1.svg"
import BigBang from "../images/awards/Big-Bang.svg"
import DesignAwards from "../images/awards/Design-Awards.svg"
import IAC from "../images/awards/IAC.svg"
import Iamia from "../images/awards/Iamia.svg"
import WMA from "../images/awards/WMA.svg"
import Webawards from "../images/awards/Webawards.svg"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import ConceptIdeation from "../images/icons/CreativeAssets/Concept-Ideation.svg"
import CreativeProduction from "../images/icons/CreativeAssets/Creative-Production.svg"
import CreativeStrategy from "../images/icons/CreativeAssets/Creative-Strategy.svg"
import Personalization from "../images/icons/CreativeAssets/Personalization.svg"
import Amazon from "../images/logos/CreativeAssets/Group20.svg"
import Lenovo from "../images/logos/CreativeAssets/Group22.svg"
import Coveo from "../images/logos/CreativeAssets/Group23.svg"
import Cohere from "../images/logos/CreativeAssets/Group24.svg"
import Airbnb from "../images/logos/CreativeAssets/Group25.svg"
import "../styles/404.css"
import CohereVideo from "../video/Show-reel.mp4"
import VideoBanner from "../video/creative-assets-banner-video.mp4"
const CreativeAssets = ({ siteTitle }) => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  const [isShowReelOpen, setIsShowReelOpen] = useState(false)

  const handleShowReelOpen = () => {
    setIsShowReelOpen(true)
  }
  const handleShowReelClose = () => {
    setIsShowReelOpen(false)
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Creative Assets | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="creative-assets content-creative">
          <section id="Banner">
            <div class="bgVideo">
              <video
                playsInline="playsinline"
                muted="muted"
                preload="yes"
                autoPlay="autoplay"
                loop="loop"
                data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
                width="100%"
                className="video-js"
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
            </div>
            <div class="bannerContent">
              <div class="container">
                <div class="banner-holder">
                  <div class="images">
                    <div className="img-one image-shape">
                      <img src={Shapeone} alt="Image" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={Shapetwo} alt="Image" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={Shapethree} alt="Image" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={Shapefour} alt="Image" />
                    </div>
                  </div>
                  <div class="title">
                    <div class="banner-title">
                      <h1>Unleash your brand potential</h1>
                      <p>Crafting stunning visuals to tell your story</p>
                      <a className="button" onClick={handleShowReelOpen}>
                        Watch showreel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isShowReelOpen && (
            <div class="video-popup">
              <ModalVideo
                channel="vimeo"
                vimeo={{ mute: false, autoplay: true }}
                isOpen={handleShowReelOpen}
                videoId="806751187"
                onClose={handleShowReelClose}
              />
              {/* <ReactImageVideoLightbox
                autoPlay
                data={[
                  {
                    url: "https://player.vimeo.com/video/806751187",
                    type: "video",
                    title: "Cohere",
                  },
                ]}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={handleShowReelClose}
              /> */}
            </div>
          )}
          <section id="PageIntro">
            <div class="container">
              <h2>Creative assets that achieve results</h2>
              <p>
                We tailor captivating creative assets and implement innovative
                digital experiences to boost brand awareness, build meaningful
                connections, and drive conversions for your business. With our
                unique concepts, you'll stand out from the competition across
                every channel.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Fuel your marketing success</h2>
                  <p>
                    Our end-to-end creative services cover everything from
                    ideation to execution, including brand identity, graphic
                    design, interactive content, immersive experiences, and
                    campaign creative to elevate your brand and engage your
                    audience effectively.
                  </p>
                  <ul className="countUp">
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div className="count-wrap">
                          <CountUp
                            end={countUpVisible ? 100 : 0}
                            duration={3}
                          />
                          <span className="plus">+</span>
                        </div>
                      </VisibilitySensor>
                      successful projects
                    </li>
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div className="count-wrap">
                          <CountUp end={countUpVisible ? 60 : 0} duration={3} />
                          <span className="plus">+</span>
                        </div>
                      </VisibilitySensor>
                      skilled professionals
                    </li>
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div className="count-wrap">
                          <CountUp end={countUpVisible ? 20 : 0} duration={3} />
                          <span className="plus">+</span>
                        </div>
                      </VisibilitySensor>
                      years of experience
                    </li>
                  </ul>
                  <a
                    id="3dsolutionvideo"
                    href="https://www.position2studios.com/"
                    target="_blank"
                    class="threedsolutionvideo"
                  >
                    View creative portfolio
                  </a>
                  <a href="https://www.position2studios.com/campaigns"></a>
                </div>
                <div className="img-wrap">
                  <div className="img-wrap-animation">
                    <img
                      src="https://www.position2.com/static/creative-slide-one-abd2c81fea1118d37ad05cf08691d1e6.jpg"
                      alt="One"
                    />
                    <img
                      src="https://www.position2.com/static/creative-slide-two-52966ef603914f4e228d44b1b4a1e9b7.jpg"
                      alt="Two"
                    />
                    <img
                      src="https://www.position2.com/static/creative-slide-one-abd2c81fea1118d37ad05cf08691d1e6.jpg"
                      alt="Three"
                    />
                    <img
                      src="https://www.position2.com/static/creative-slide-two-52966ef603914f4e228d44b1b4a1e9b7.jpg"
                      alt="Four"
                    />
                    <img
                      src="https://www.position2.com/static/creative-slide-one-abd2c81fea1118d37ad05cf08691d1e6.jpg"
                      alt="Five"
                    />
                    <img
                      src="https://www.position2.com/static/creative-slide-two-52966ef603914f4e228d44b1b4a1e9b7.jpg"
                      alt="Six"
                    />
                  </div>
                  {/* <img
                    alt="Copy write"
                    width="500"
                    height="500"
                    src={CopyRightRoundOne}
                  /> */}
                </div>
              </div>
            </div>
          </section>
          <section id="IconColSection">
            <div class="container">
              <div class="inner-wrapper">
                <h2>How we harness the power of AI for creative designs</h2>
                <div class="icon-cardwrapper">
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Unmatched Uptime"
                        width="100"
                        height="100"
                        src={CreativeStrategy}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Creative Strategy <br />
                        </strong>
                        We leverage data-driven insights to inform and optimize
                        our creative strategy.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Smart SEO Insights"
                        width="100"
                        height="100"
                        src={ConceptIdeation}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Concept Ideation <br />
                        </strong>
                        We use generative AI to spark unique, out-of-the-box{" "}
                        <br />
                        design concepts.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Future-Proof Technology"
                        width="100"
                        height="100"
                        src={Personalization}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Personalization <br />
                        </strong>
                        Utilizing AI to analyze data and create highly
                        personalized design experiences.
                      </p>
                    </div>
                  </div>
                  <div class="icon-card">
                    <div class="img-wrap">
                      <img
                        alt="Intuitive Search"
                        width="100"
                        height="100"
                        src={CreativeProduction}
                      />
                    </div>
                    <div class="text-wrap">
                      <p>
                        <strong>
                          Creative Production <br />
                        </strong>
                        Our streamlined creative production with AI-enhanced
                        workflows is efficient and <br />
                        cost-effective.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="AwardsSection">
            <div className="awards">
              <div className="container">
                <ul>
                  <li>
                    <img src={DesignAwards} alt="Logo" />
                  </li>
                  <li>
                    <img src={IAC} alt="Logo" />
                  </li>
                  <li>
                    <img src={BigBang} alt="Logo" />
                  </li>
                  <li>
                    <img src={WMA} alt="Logo" />
                  </li>
                  <li>
                    <img src={Iamia} alt="Logo" />
                  </li>
                  <li>
                    <img src={Webawards} alt="Logo" />
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div id="InnerSlider" className="home-testimonial">
            <div className="slider-wrapper">
              <div className="inner-slider">
                <div className="each-slider">
                  <div className="banner-cover">
                    <video
                      width="100%"
                      playsInline="playsinline"
                      muted="muted"
                      preload="yes"
                      autoPlay="autoplay"
                      loop="loop"
                      className="video-js BiggerScreen"
                      data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
                    >
                      <source src={CohereVideo} type="video/mp4" />
                    </video>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        {/* <p className="eyebrow">Recent Work</p> */}
                        <h2>Our Portfolio</h2>
                        <p>
                          Take a look at a selection of our creative products,
                          and some award-winning work.
                        </p>
                        <a
                          target="_blank"
                          href="https://www.position2studios.com/"
                        >
                          View portfolio
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="PartnerLogoSection">
            <div class="container">
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Cohere} alt="Cohere" />
                </div>
                <div class="each-logo">
                  <img src={Lenovo} alt="Lenovo" />
                </div>
                <div class="each-logo">
                  <img src={Coveo} alt="Coveo" />
                </div>
                <div class="each-logo">
                  <img src={Amazon} alt="Amazon" />
                </div>
                <div class="each-logo">
                  <img src={Macroair} alt="Macroair" />
                </div>
                <div class="each-logo">
                  <img src={Airbnb} alt="Airbnb" />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Let's bring your brand vision to life together!</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default CreativeAssets
